
import { defineComponent, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import {
  useNotifChannelManager,
  NotifChannel,
  NotifChannelType,
  NotifChannelState,
} from '@/alerting/use-notif-channels'

// Components

import NotifChannelStateAvatar from '@/alerting/NotifChannelStateAvatar.vue'

export default defineComponent({
  name: 'NotifChannelsTable',
  components: { NotifChannelStateAvatar },

  props: {
    channel: {
      type: Object as PropType<NotifChannel>,
      required: true,
    },
  },

  setup(_props, ctx) {
    const confirm = useConfirm()
    const man = useNotifChannelManager()

    function pauseChannel(channel: NotifChannel) {
      man.pause(channel.id).then(() => {
        ctx.emit('change')
      })
    }

    function unpauseChannel(channel: NotifChannel) {
      man.unpause(channel.id).then(() => {
        ctx.emit('change')
      })
    }

    function deleteChannel(channel: NotifChannel) {
      confirm
        .open('Delete channel', `Do you really want to delete "${channel.name}" channel?`)
        .then(
          () => {
            man.delete(channel.id).then(() => {
              ctx.emit('change')
            })
          },
          () => {},
        )
    }

    function channelRoute(channel: NotifChannel) {
      switch (channel.type) {
        case NotifChannelType.Slack:
          return { name: 'NotifChannelShowSlack', params: { channelId: channel.id } }
        case NotifChannelType.Telegram:
          return { name: 'NotifChannelShowTelegram', params: { channelId: channel.id } }
        case NotifChannelType.Webhook:
          return { name: 'NotifChannelShowWebhook', params: { channelId: channel.id } }
        case NotifChannelType.Alertmanager:
          return { name: 'NotifChannelShowAlertmanager', params: { channelId: channel.id } }
      }
    }

    return {
      NotifChannelState,

      man,
      pauseChannel,
      unpauseChannel,
      deleteChannel,
      channelRoute,
    }
  },
})
