
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { Project } from '@/org/use-projects'

export default defineComponent({
  name: 'ProjectDsn',

  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    dsn: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    useTitle(
      computed((): string => {
        return `Data Source Name | ${props.project.name}`
      }),
    )

    const headers = [{ text: 'DSN', value: 'dsn', sortable: false }]

    const tokens = computed(() => {
      if (!props.project) {
        return []
      }
      return [{ dsn: props.dsn }]
    })

    return { headers, tokens }
  },
})
