
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import GroupMetricsItem from '@/metrics/GroupMetricsItem.vue'

// Misc
import { Metric } from '@/metrics/types'
import { buildPrefixes, Prefix } from '@/models/key-prefixes'

export default defineComponent({
  name: 'GroupMetrics',
  components: { GroupMetricsItem },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    metrics: {
      type: Array as PropType<Metric[]>,
      required: true,
    },
    where: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const searchInput = shallowRef('')
    const activePrefix = shallowRef<Prefix>()
    const prefixes = computed(() => {
      const keys = props.metrics.map((metric) => metric.name)
      return buildPrefixes(keys)
    })

    const filteredMetrics = computed((): Metric[] => {
      let metrics = props.metrics

      if (activePrefix.value) {
        metrics = props.metrics.filter((metric) => {
          return activePrefix.value!.keys.includes(metric.name)
        })
      }

      if (searchInput.value) {
        metrics = fuzzyFilter(metrics, searchInput.value, { key: 'name' })
      }

      return metrics
    })

    return {
      searchInput,
      activePrefix,
      prefixes,
      filteredMetrics,
    }
  },
})
