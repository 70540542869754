
import { defineComponent, shallowRef } from 'vue'

// Components
import DashboardForm from '@/metrics/DashboardForm.vue'
import DashboardYamlForm from '@/metrics/DashboardYamlForm.vue'

export default defineComponent({
  name: 'DashboardMenu',
  components: { DashboardForm, DashboardYamlForm },

  setup() {
    const menu = shallowRef(false)
    const newDialog = shallowRef(false)
    const newYamlDialog = shallowRef(false)

    function closeDialog() {
      newDialog.value = false
      newYamlDialog.value = false
      menu.value = false
    }

    return {
      menu,
      newDialog,
      newYamlDialog,
      closeDialog,
    }
  },
})
