
import { defineComponent, ref, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useTimeseries, useStyledTimeseries } from '@/metrics/use-query'

// Components
import UnitPicker from '@/components/UnitPicker.vue'
import MonitorMetricFormPanes from '@/alerting/MonitorMetricFormPanes.vue'
import MonitorMetricFormOptions from '@/alerting/MonitorMetricFormOptions.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import MetricChart from '@/metrics/MetricChart.vue'
import ChartLegendTable from '@/metrics/ChartLegendTable.vue'

// Misc
import { EventBus } from '@/models/eventbus'
import { updateColumnMap, emptyMetricColumn, Metric, MetricColumn } from '@/metrics/types'
import { MetricMonitor } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorMetricForm',
  components: {
    MonitorMetricFormPanes,
    MonitorMetricFormOptions,
    UnitPicker,
    MetricsPicker,
    MetricsQueryBuilder,
    MetricChart,
    ChartLegendTable,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    metrics: {
      type: Array as PropType<Metric[]>,
      required: true,
    },
    monitor: {
      type: Object as PropType<MetricMonitor>,
      required: true,
    },
    columnMap: {
      type: Object as PropType<Record<string, MetricColumn>>,
      default: undefined,
    },
  },

  setup(props, ctx) {
    const eventBus = new EventBus()

    const internalColumnMap = ref<Record<string, MetricColumn>>({})
    watch(
      () => props.columnMap,
      (columnMap) => {
        if (!columnMap) {
          return
        }
        for (let key in columnMap) {
          internalColumnMap.value[key] = columnMap[key]
        }
      },
      { immediate: true },
    )

    const uql = useUql()
    const activeMetrics = useActiveMetrics(computed(() => props.monitor.params.metrics))
    const axiosParams = computed(() => {
      if (!props.monitor.params.query) {
        return undefined
      }

      const metrics = props.monitor.params.metrics.filter((m) => m.name && m.alias)
      if (!metrics.length) {
        return undefined
      }

      return {
        ...props.dateRange.axiosParams(),
        time_offset: props.monitor.params.timeOffset,

        metric: metrics.map((m) => m.name),
        alias: metrics.map((m) => m.alias),
        query: props.monitor.params.query,
      }
    })

    const timeseries = useTimeseries(() => {
      return axiosParams.value
    })

    const styledTimeseries = useStyledTimeseries(
      computed(() => timeseries.items),
      computed(() => internalColumnMap.value),
      computed(() => ({})),
    )

    watch(
      () => props.monitor.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )
    watch(
      () => uql.query,
      (query) => {
        props.monitor.params.query = query
      },
    )

    watch(
      () => timeseries.query,
      (queryInfo) => {
        if (queryInfo) {
          uql.setQueryInfo(queryInfo)
        }
      },
      { immediate: true },
    )
    watch(
      () => timeseries.columns,
      (columns) => {
        updateColumnMap(internalColumnMap.value, columns, emptyMetricColumn)

        const params = props.monitor.params
        if (params.column in internalColumnMap.value) {
          internalColumnMap.value[params.column].unit = params.columnUnit
        }
      },
    )

    return {
      internalColumnMap,
      eventBus,

      uql,
      activeMetrics,
      axiosParams,
      timeseries,
      styledTimeseries,
    }
  },
})
