
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useGridRowManager } from '@/metrics/use-dashboards'

// Misc
import { requiredRule } from '@/util/validation'
import { GridRow } from '@/metrics/types'

export default defineComponent({
  name: 'GridRowForm',

  props: {
    row: {
      type: Object as PropType<GridRow>,
      required: true,
    },
  },

  setup(props, ctx) {
    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = { title: [requiredRule] }

    const gridRowMan = useGridRowManager()
    function submit() {
      if (!form.value.validate()) {
        return
      }
      gridRowMan.save(props.row).then(() => {
        ctx.emit('save')
      })
    }

    return {
      form,
      isValid,
      rules,

      gridRowMan,
      submit,
    }
  },
})
