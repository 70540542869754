
import { defineComponent, PropType } from 'vue'

// Composables
import { Item } from '@/use/datasource'

export default defineComponent({
  name: 'GroupingToggle',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },

  setup(props, ctx) {
    function onChange(value: string[]) {
      if (!props.loading) {
        ctx.emit('input', value)
      }
    }

    return { onChange }
  },
})
