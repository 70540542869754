
import { defineComponent, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { useYamlDashboard } from '@/metrics/use-dashboards'
import { injectForceReload } from '@/use/force-reload'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashboardYamlCard',

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      default: undefined,
    },
  },

  setup(props) {
    const route = useRoute()
    const forceReload = injectForceReload()

    const dash = useYamlDashboard(() => {
      if (!props.dashboard.id) {
        return
      }

      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/dashboards/${props.dashboard.id}/yaml`,
        params: forceReload.params,
      }
    })

    return { dash }
  },
})
