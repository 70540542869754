
import { defineComponent, computed, watchEffect, onMounted, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { injectForceReload } from '@/use/force-reload'

// Components
import DateRangePickerMenu from '@/components/date/DateRangePickerMenu.vue'
import PeriodPickerMenu from '@/components/date/PeriodPickerMenu.vue'

// Misc
import { periodsForDays, Period } from '@/models/period'
import { HOUR } from '@/util/fmt'

export default defineComponent({
  name: 'DateRangePicker',
  components: { DateRangePickerMenu, PeriodPickerMenu },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    rangeDays: {
      type: Number,
      default: 3,
    },
    defaultPeriod: {
      type: Number,
      default: HOUR,
    },
  },

  setup(props) {
    const forceReload = injectForceReload()

    const periods = computed(() => {
      return periodsForDays(props.rangeDays)
    })

    onMounted(() => {
      watchEffect(() => {
        if (!periods.value.length || props.dateRange.duration) {
          return
        }

        const period = periods.value.find((p: Period) => p.milliseconds === props.defaultPeriod)
        if (period) {
          props.dateRange.changeDuration(period.milliseconds)
        } else {
          props.dateRange.changeDuration(periods.value[0].milliseconds)
        }
      })
    })

    return { forceReload, periods }
  },
})
