
import { defineComponent, computed, PropType } from 'vue'

// Compsables
import { Alert, AlertType, AlertStatus } from '@/alerting/use-alerts'

// Components
import MetricMonitorTrigger from '@/alerting/MetricMonitorTrigger.vue'
import AlertSparklineError from '@/alerting/AlertSparklineError.vue'
import AlertSparklineMetric from '@/alerting/AlertSparklineMetric.vue'
import AlertChips from '@/alerting/AlertChips.vue'

export default defineComponent({
  name: 'AlertsTableRow',
  components: { MetricMonitorTrigger, AlertSparklineError, AlertSparklineMetric, AlertChips },

  props: {
    alert: {
      type: Object as PropType<Alert>,
      required: true,
    },
  },

  setup(props) {
    const statusColor = computed(() => {
      switch (props.alert.status) {
        case AlertStatus.Open:
          return 'red darken-2'
        default:
          return 'green darken-2'
      }
    })

    return {
      AlertStatus,
      AlertType,
      statusColor,
    }
  },
})
