
import { defineComponent } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'

// Components
import CheatsheetCard from '@/tracing/CheatsheetCard.vue'

export default defineComponent({
  name: 'Cheatcheet',
  components: { CheatsheetCard },

  setup() {
    useTitle('Querying Spans Cheatsheet')
    return {}
  },
})
