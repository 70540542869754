
import { defineComponent, shallowRef } from 'vue'
import { refDebounced } from '@/use/ref-debounced'

// Composables
import { useSyncQueryParams } from '@/use/router'
import { useProject } from '@/org/use-projects'
import { useDashboards } from '@/metrics/use-dashboards'

// Components
import DashboardNewMenu from '@/metrics/DashboardNewMenu.vue'
import DashboardsTable from '@/metrics/DashboardsTable.vue'

export default defineComponent({
  name: 'DashboardList',
  components: {
    DashboardNewMenu,
    DashboardsTable,
  },

  setup(props) {
    const searchInput = shallowRef('')
    const debouncedSearchInput = refDebounced(searchInput, 600)
    const pinnedFilter = shallowRef(false)

    const project = useProject()
    const dashboards = useDashboards(() => {
      const params: Record<string, any> = {}

      if (debouncedSearchInput.value) {
        params.q = debouncedSearchInput.value
      }

      if (pinnedFilter.value) {
        params.pinned = true
      }

      return params
    })

    useSyncQueryParams({
      fromQuery(queryParams) {
        queryParams.setDefault('sort_by', 'name')
        queryParams.setDefault('sort_desc', false)

        dashboards.order.parseQueryParams(queryParams)

        searchInput.value = queryParams.string('q')
        debouncedSearchInput.flush()
      },
      toQuery() {
        const queryParams: Record<string, any> = {
          q: debouncedSearchInput.value,
          ...dashboards.order.queryParams(),
        }

        return queryParams
      },
    })

    return {
      project,
      dashboards,
      searchInput,
    }
  },
})
