
import { defineComponent, shallowRef } from 'vue'

// Composables
import { useMonitorManager, routeForMonitor } from '@/alerting/use-monitors'
import { useRouterOnly } from '@/use/router'

// Misc
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'MonitorYamlForm',

  setup(_props, ctx) {
    const isValid = shallowRef(true)
    const rules = {
      yaml: [requiredRule],
    }

    const router = useRouterOnly()
    const yaml = shallowRef('')
    const monitorMan = useMonitorManager()

    function saveYaml() {
      monitorMan.createMonitorFromYaml(yaml.value).then((monitors) => {
        ctx.emit('create')

        if (monitors.length === 1) {
          router.push(routeForMonitor(monitors[0]))
        }
      })
    }

    return {
      isValid,
      rules,
      yaml,
      monitorMan,

      saveYaml,
    }
  },
})
