
import { defineComponent, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useProject } from '@/org/use-projects'
import { useSlackNotifChannel } from '@/alerting/use-notif-channels'

// Components
import NotifChannelSlackForm from '@/alerting/NotifChannelSlackForm.vue'

export default defineComponent({
  name: 'ChannelShowSlack',
  components: { NotifChannelSlackForm },

  setup() {
    useTitle('Slack Channel')
    const route = useRoute()
    const project = useProject()

    const channel = useSlackNotifChannel(() => {
      const { projectId, channelId } = route.value.params
      return {
        url: `/internal/v1/projects/${projectId}/notification-channels/slack/${channelId}`,
      }
    })

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
        exact: true,
      })

      bs.push({
        text: 'Channels',
        to: {
          name: 'NotifChannelList',
        },
        exact: true,
      })

      bs.push({ text: 'Slack' })

      return bs
    })

    return { channel, breadcrumbs }
  },
})
