
import { truncate } from 'lodash-es'
import { defineComponent, shallowRef, computed, watch, proxyRefs } from 'vue'

// Composables
import { useSyncQueryParams } from '@/use/router'
import { useTitle } from '@vueuse/core'
import { useDateRange, UseDateRange } from '@/use/date-range'
import { useSystems } from '@/tracing/system/use-systems'
import { useTrace, UseTrace } from '@/tracing/use-trace'
import { createQueryEditor } from '@/use/uql'
import { useAnnotations } from '@/org/use-annotations'

// Components
import SystemGroupPicker from '@/tracing/system/SystemGroupPicker.vue'
import FixedDateRangePicker from '@/components/date/FixedDateRangePicker.vue'
import PercentilesChartLazy from '@/components/PercentilesChartLazy.vue'
import SpanSystemBarChart from '@/components/SpanSystemBarChart.vue'
import TraceTabs from '@/tracing/TraceTabs.vue'

// Misc
import { AttrKey, SystemName } from '@/models/otel'

export default defineComponent({
  name: 'TraceShow',
  components: {
    SystemGroupPicker,
    FixedDateRangePicker,
    PercentilesChartLazy,
    SpanSystemBarChart,
    TraceTabs,
  },

  setup() {
    useTitle('View trace')
    const rootSpanId = shallowRef('')

    const dateRange = useDateRange()
    const trace = useTrace(() => {
      if (!rootSpanId.value) {
        return {}
      }

      return { root_span_id: rootSpanId.value }
    })

    const annotations = useAnnotations(() => {
      return {
        ...dateRange.axiosParams(),
      }
    })
    const systems = useSystems(() => {
      return {
        ...dateRange.axiosParams(),
      }
    })

    const axiosParams = computed(() => {
      if (!trace.root) {
        return
      }
      return {
        ...dateRange.axiosParams(),
        system: trace.root.system,
        group_id: trace.root.groupId,
      }
    })

    const groupRoute = computed(() => {
      if (!trace.root) {
        return
      }

      return {
        name: 'SpanList',
        query: {
          ...dateRange.queryParams(),
          system: trace.root.system,
          query: createQueryEditor()
            .exploreAttr(AttrKey.spanGroupId, true)
            .where(AttrKey.spanGroupId, '=', trace.root.groupId)
            .toString(),
        },
      }
    })

    const exploreTraceRoute = computed(() => {
      if (!trace.root) {
        return
      }

      return {
        name: 'SpanGroupList',
        query: {
          ...dateRange.queryParams(),
          system: SystemName.SpansAll,
          query: [
            `where ${AttrKey.spanTraceId} = ${trace.root.traceId}`,
            `group by ${AttrKey.spanGroupId}`,
            AttrKey.spanCount,
            AttrKey.spanErrorCount,
            `{p50,p90,p99,sum}(${AttrKey.spanDuration})`,
          ].join(' | '),
          plot: null,
        },
      }
    })

    watch(
      () => trace.root,
      (span) => {
        if (span) {
          useTitle(span.name)
        }
      },
    )

    useSyncQueryParams({
      fromQuery(queryParams) {
        rootSpanId.value = queryParams.string('root_span')
      },
      toQuery() {
        const queryParams: Record<string, any> = {}

        if (rootSpanId.value) {
          queryParams.root_span = rootSpanId.value
        }

        return queryParams
      },
    })

    return {
      dateRange,
      annotations,
      systems,

      trace,
      rootSpanId,

      meta: useMeta(dateRange, trace),

      axiosParams,
      groupRoute,
      exploreTraceRoute,
    }
  },
})

function useMeta(dateRange: UseDateRange, trace: UseTrace) {
  const breadcrumbs = computed(() => {
    const root = trace.root
    const bs: any[] = []

    if (!root) {
      return bs
    }

    if (root.system) {
      bs.push({
        text: root.system,
        to: {
          name: 'SpanGroupList',
          query: {
            ...dateRange.queryParams(),
            system: root.system,
          },
        },
        exact: true,
      })
    }

    if (root.system && root.groupId) {
      bs.push({
        text: truncate(root.displayName, { length: 60 }),
        to: {
          name: 'SpanList',
          query: {
            ...dateRange.queryParams(),
            system: root.system,
            query: createQueryEditor()
              .exploreAttr(AttrKey.spanGroupId, true)
              .where(AttrKey.spanGroupId, '=', root.groupId)
              .toString(),
          },
        },
        exact: true,
      })
    }

    bs.push({
      text: trace.id,
    })

    return bs
  })

  return proxyRefs({ breadcrumbs })
}
