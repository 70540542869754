
import { defineComponent, computed, PropType } from 'vue'

// Misc
import { MonitorType } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorTypeIcon',

  props: {
    type: {
      type: String as PropType<MonitorType>,
      required: true,
    },
  },

  setup(props) {
    const icon = computed(() => {
      switch (props.type) {
        case MonitorType.Metric:
          return 'mdi-chart-line'
        case MonitorType.Error:
          return 'mdi-bug-outline'
        default:
          return 'mdi-alert'
      }
    })

    return { icon }
  },
})
