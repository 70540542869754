
import { defineComponent, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useTableQuery } from '@/metrics/use-query'

// Components
import DashTableFormPanes from '@/metrics/DashTableFormPanes.vue'
import SinglePanel from '@/components/SinglePanel.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import TimeseriesTable from '@/metrics/TimeseriesTable.vue'
import TableColumnOptionsForm from '@/metrics/TableColumnOptionsForm.vue'

// Misc
import { updateColumnMap, assignColors, emptyTableColumn, Dashboard } from '@/metrics/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'DashTableForm',
  components: {
    DashTableFormPanes,
    SinglePanel,
    MetricsPicker,
    MetricsQueryBuilder,
    TimeseriesTable,
    TableColumnOptionsForm,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const rules = { name: [requiredRule] }

    const uql = useUql()
    const activeMetrics = useActiveMetrics(computed(() => props.dashboard.tableMetrics))

    const tableQuery = useTableQuery(
      () => {
        if (!props.dashboard.tableQuery || !props.dashboard.tableMetrics.length) {
          return undefined
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.dashboard.tableMetrics.map((m) => m.name),
          alias: props.dashboard.tableMetrics.map((m) => m.alias),
          query: props.dashboard.tableQuery,
        }
      },
      computed(() => props.dashboard.tableColumnMap),
    )

    watch(
      () => tableQuery.aggColumns,
      (aggColumns) => {
        updateColumnMap(props.dashboard.tableColumnMap, aggColumns, emptyTableColumn)
        assignColors(props.dashboard.tableColumnMap, aggColumns)
      },
    )

    watch(
      () => props.dashboard.tableQuery,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.dashboard.tableQuery = query
      },
    )
    watch(
      () => tableQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    return {
      rules,

      uql,
      activeMetrics,
      tableQuery,
    }
  },
})
