
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Misc
import { Span } from '@/models/span'

export default defineComponent({
  name: 'SpansTableSettings',

  props: {
    spans: {
      type: Array as PropType<Span[]>,
      required: true,
    },
  },

  setup(props, ctx) {
    const dialog = shallowRef(false)

    const attrs = computed((): string[] => {
      if (!dialog.value) {
        return []
      }

      const AttrKeys: Record<string, null> = {}

      for (let span of props.spans) {
        const keys = Object.keys(span.attrs).filter((key) => !key.startsWith('_'))
        for (let key of keys) {
          AttrKeys[key] = null
        }
      }

      return Object.keys(AttrKeys).sort()
    })

    return { dialog, attrs }
  },
})
