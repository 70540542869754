
import { defineComponent, PropType } from 'vue'

// Composables
import { useRoute, useSyncQueryParams } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { UseSystems } from '@/tracing/system/use-systems'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import HelpCard from '@/tracing/HelpCard.vue'

export default defineComponent({
  name: 'TracingPlaceholder',
  components: { DateRangePicker, HelpCard },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    systems: {
      type: Object as PropType<UseSystems>,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute()

    useSyncQueryParams({
      fromQuery(queryParams) {
        props.dateRange.parseQueryParams(queryParams)
      },
      toQuery() {
        return {
          ...route.value.query,
          ...props.dateRange.queryParams(),
        }
      },
    })

    function changeAround(dt: string) {
      props.dateRange.changeAround(dt)
    }

    return { changeAround }
  },
})
