
import { defineComponent, PropType } from 'vue'

// Composables
import { useUser } from '@/org/use-users'
import { Project } from '@/org/use-projects'

export default defineComponent({
  name: 'ProjectMenu',

  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },

  setup() {
    const user = useUser()

    function saveLastProjectId(projectId: number) {
      user.lastProjectId = projectId
    }

    return { user, saveLastProjectId }
  },
})
