
import Color from 'color'
import { defineComponent, ref, shallowRef, computed, PropType } from 'vue'

// Composables
import { useAnnotationManager, Attr } from '@/org/use-annotations'

// Components
import AnnotationAttrRow from '@/alerting/AnnotationAttrRow.vue'

// Misc
import { Annotation } from '@/org/use-annotations'
import { requiredRule, minMaxStringLengthRule } from '@/util/validation'

export default defineComponent({
  name: 'AnnotationForm',
  components: { AnnotationAttrRow },

  props: {
    annotation: {
      type: Object as PropType<Annotation>,
      required: true,
    },
  },

  setup(props, ctx) {
    const attrs = ref<Attr[]>([
      ...Object.entries(props.annotation.attrs).map(([key, value]) => ({ key, value })),
      { key: '', value: '' },
    ])
    const annotationMan = useAnnotationManager()

    const color = computed({
      get(): string {
        return Color(props.annotation.color).hex()
      },
      set(color: string) {
        props.annotation.color = color
      },
    })

    const form = shallowRef()
    const isValid = shallowRef(true)
    const rules = {
      name: [requiredRule],
      description: [minMaxStringLengthRule(0, 5000)],
    }

    function submit() {
      save().then(() => {
        ctx.emit('click:save')
        ctx.emit('click:close')
      })
    }

    function save() {
      if (!form.value.validate()) {
        return Promise.reject()
      }

      props.annotation.attrs = attrs.value
        .filter((attr) => attr.key !== '')
        .reduce((acc: Record<string, string>, attr) => {
          acc[attr.key] = attr.value
          return acc
        }, {})

      if (props.annotation.id) {
        return annotationMan.update(props.annotation)
      }
      return annotationMan.create(props.annotation)
    }

    function addAttr() {
      attrs.value.push({ key: '', value: '' })
    }

    function removeAttr(i: number) {
      attrs.value.splice(i, 1)
    }

    return {
      annotationMan,
      color,
      attrs,

      form,
      isValid,
      rules,

      submit,
      addAttr,
      removeAttr,
    }
  },
})
