
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseOrder } from '@/use/order'
import { exploreAttr } from '@/use/uql'

// Components
import SparklineChart from '@/components/SparklineChart.vue'
import TimeseriesTableRow from '@/metrics/TimeseriesTableRow.vue'

// Misc
import { StyledColumnInfo, TableRowData } from '@/metrics/types'
import { AttrKey } from '@/models/otel'
import { Unit } from '@/util/fmt'

export default defineComponent({
  name: 'TimeseriesTable',
  components: { SparklineChart, TimeseriesTableRow },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<TableRowData[]>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    aggColumns: {
      type: Array as PropType<StyledColumnInfo[]>,
      required: true,
    },
    groupingColumns: {
      type: Array as PropType<string[]>,
      required: true,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const headers = computed(() => {
      const headers = []
      for (let colName of props.groupingColumns) {
        headers.push({ text: colName, value: colName, sortable: true })
      }
      for (let col of props.aggColumns) {
        headers.push({ text: col.name, value: col.name, sortable: true, align: 'start' })
      }
      return headers
    })

    function routeForSpanList(groupId: string) {
      const query = exploreAttr(AttrKey.spanGroupId, true)
      return {
        name: 'SpanList',
        query: {
          query: `${query} | where ${AttrKey.spanGroupId} = ${groupId}`,
        },
      }
    }

    return {
      Unit,
      AttrKey,

      headers,

      routeForSpanList,
    }
  },
})
