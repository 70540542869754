
import { defineComponent } from 'vue'

// Composables
import { injectForceReload } from '@/use/force-reload'

export default defineComponent({
  name: 'ForceReloadBtn',

  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const forceReload = injectForceReload()
    return { forceReload }
  },
})
