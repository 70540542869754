
import { defineComponent } from 'vue'

// Composables
import { useStorage } from '@/use/local-storage'
import { useUser } from '@/org/use-users'
import { useProject } from '@/org/use-projects'

// Components
import ProjectMenu from '@/system/ProjectMenu.vue'
import ProjectNavigationList from '@/system/ProjectNavigationList.vue'
import GetStartedMenu from '@/system/GetStartedMenu.vue'
import HowToMenu from '@/system/HowToMenu.vue'
import UserMenu from '@/system/UserMenu.vue'

export default defineComponent({
  name: 'AppNavigation',
  components: { ProjectMenu, ProjectNavigationList, GetStartedMenu, HowToMenu, UserMenu },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const temporary = useStorage('navigation-temporary', false)
    const mini = useStorage('navigation-mini', false)

    const user = useUser()
    const project = useProject()

    return {
      temporary,
      mini,

      user,
      project,
    }
  },
})
