
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { defaultMetricAlias } from '@/metrics/use-metrics'

// Misc
import { unitShortName } from '@/util/fmt'
import { requiredRule } from '@/util/validation'
import { escapeRe } from '@/util/string'

// Misc
import { emptyMetric, Metric, MetricAlias } from '@/metrics/types'

export default defineComponent({
  name: 'MetricPicker',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array as PropType<Metric[]>,
      required: true,
    },

    value: {
      type: Object as PropType<MetricAlias>,
      default: undefined,
    },
    activeMetrics: {
      type: Array as PropType<MetricAlias[]>,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const metricAliasRef = shallowRef()
    const searchInput = shallowRef('')

    const metricName = shallowRef(props.value?.name ?? '')
    const metricAlias = shallowRef(props.value?.alias ?? '')

    const formRef = shallowRef()
    const isValid = shallowRef(false)
    const rules = computed(() => {
      return {
        name: [requiredRule],
        alias: [
          (v: string) => {
            if (!metricName.value) {
              return true
            }
            if (!v) {
              return 'Alias is required'
            }
            if (!/^[a-z0-9_]*$/i.test(v)) {
              return 'Only letters and numbers are allowed'
            }

            if (v !== props.value?.alias) {
              const found = props.activeMetrics.find((m) => m.alias === v)
              if (found) {
                return 'Alias is duplicated'
              }
            }

            return true
          },
        ],
      }
    })

    const filteredMetrics = computed((): Metric[] => {
      let metrics = props.metrics.slice()
      if (searchInput.value) {
        metrics = fuzzyFilter(metrics, searchInput.value, { key: 'name' })
      }
      if (props.value && props.value.name) {
        const index = metrics.findIndex((m) => m.name === props.value.name)
        if (index === -1) {
          const metric = emptyMetric()
          metric.name = props.value.name
          metrics.push(metric)
        }
      }
      return metrics
    })

    const applyDisabled = computed((): boolean => {
      if (!metricName.value || !metricAlias.value) {
        return true
      }
      if (props.value) {
        if (metricName.value !== props.value.name || metricAlias.value !== props.value.alias) {
          return false
        }
      }
      if (!createRegexp(metricAlias.value).test(props.query)) {
        return false
      }
      return true
    })

    function apply() {
      metricAlias.value = metricAlias.value.toLowerCase()
      ctx.emit('click:apply', { name: metricName.value, alias: metricAlias.value })
      if (!props.value) {
        reset()
      }
    }

    function reset() {
      metricName.value = ''
      metricAlias.value = ''
      formRef.value.reset()
    }

    function onMetricNameChange(metricName: string | null) {
      if (!metricName) {
        return
      }

      metricAlias.value = defaultMetricAlias(metricName)
      metricAliasRef.value.focus()
    }

    return {
      searchInput,
      metricAliasRef,
      metricName,
      metricAlias,

      formRef,
      isValid,
      rules,
      filteredMetrics,

      applyDisabled,
      apply,

      unitShortName,
      onMetricNameChange,
    }
  },
})

function createRegexp(alias: string, flags = '') {
  return new RegExp(escapeRe('$' + alias) + '\\b', flags)
}
