
import { defineComponent, computed } from 'vue'

// Composables
import { splitQuery } from '@/use/uql'

// Components
import UptraceQueryChip from '@/components/UptraceQueryChip.vue'

export default defineComponent({
  name: 'UqlCardReadonly',
  components: { UptraceQueryChip },

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const parts = computed(() => {
      return splitQuery(props.query)
    })

    return { parts }
  },
})
