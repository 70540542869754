
import { defineComponent, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useProject } from '@/org/use-projects'

export default defineComponent({
  name: 'Project',

  setup() {
    const project = useProject()
    useTitle(
      computed(() => {
        return project.data?.name ?? 'Project'
      }),
    )

    const breadcrumbs = computed(() => {
      const ss: any[] = [{ text: 'Projects' }]

      if (project.data) {
        ss.push({ text: project.data.name })
      } else {
        ss.push({ text: 'Project' })
      }

      return ss
    })

    return {
      breadcrumbs,

      project,
    }
  },
})
