
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { ApiError } from '@/use/promise'

interface Progress {
  rows: number
  bytes: number
  totalRows: number
}

export default defineComponent({
  name: 'ApiErrorCard',
  props: {
    error: {
      type: Object as PropType<ApiError>,
      required: true,
    },
  },

  setup(props) {
    const progress = computed((): Progress => {
      return props.error.data?.progress ?? {}
    })

    return { progress }
  },
})
