
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { createQueryEditor, injectQueryStore } from '@/use/uql'
import { useGroup } from '@/tracing/use-groups'

// Components
import PercentilesChartLazy from '@/components/PercentilesChartLazy.vue'
import UqlCardReadonly from '@/components/UqlCardReadonly.vue'
import PagedGroupsCardLazy from '@/tracing/PagedGroupsCardLazy.vue'

// Misc
import { isSpanSystem, SystemName, AttrKey } from '@/models/otel'
import { Unit } from '@/util/fmt'

export default defineComponent({
  name: 'GroupInfoCard',
  components: {
    PercentilesChartLazy,
    UqlCardReadonly,
    PagedGroupsCardLazy,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    annotations: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const route = useRoute()
    const { where } = injectQueryStore()

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.system,
        group_id: props.groupId,
        query: where.value,
      }
    })

    const group = useGroup(() => {
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/tracing/${projectId}/groups/${props.groupId}`,
        params: axiosParams.value,
      }
    })

    const isSpan = computed((): boolean => {
      return isSpanSystem(props.system)
    })

    const slowestSpansQuery = computed(() => {
      return createQueryEditor()
        .exploreAttr(AttrKey.spanGroupId, true)
        .where(AttrKey.spanGroupId, '=', props.groupId)
        .add(where.value)
        .toString()
    })
    const slowestSpansAxiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.system,
        query: slowestSpansQuery.value,
      }
    })

    const failedSpansQuery = computed(() => {
      return createQueryEditor()
        .exploreAttr(AttrKey.spanGroupId, true)
        .where(AttrKey.spanGroupId, '=', props.groupId)
        .where(AttrKey.spanStatusCode, '=', 'error')
        .add(where.value)
        .toString()
    })
    const failedSpansAxiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.system,
        query: failedSpansQuery.value,
      }
    })

    const statusMessagesQuery = computed(() => {
      return createQueryEditor()
        .exploreAttr(AttrKey.spanStatusMessage, true)
        .where(AttrKey.spanGroupId, '=', props.groupId)
        .where(AttrKey.spanStatusMessage, '!=', '')
        .add(where.value)
        .toString()
    })

    return {
      SystemName,
      AttrKey,
      Unit,

      axiosParams,
      group,
      isSpan,
      slowestSpansQuery,
      slowestSpansAxiosParams,
      failedSpansQuery,
      failedSpansAxiosParams,
      statusMessagesQuery,
    }
  },
})
