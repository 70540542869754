
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DialogCard',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
})
