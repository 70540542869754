
import { defineComponent, shallowRef, computed } from 'vue'

import Prism from 'vue-prism-component'
import CopyBtn from '@/components/CopyBtn.vue'

export default defineComponent({
  name: 'PrismCode',
  components: { Prism, CopyBtn },

  props: {
    code: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      default: 'markup',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    targetStyle: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const el = shallowRef<any>()

    const wrap = computed((): boolean => {
      return !props.code.includes('\n')
    })

    function target() {
      return el.value!.$el.querySelector('pre')
    }

    return {
      el,
      wrap,
      target,
    }
  },
})
