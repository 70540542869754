
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import { useMonitorManager, routeForMonitor } from '@/alerting/use-monitors'
import { AlertStatus } from '@/alerting/use-alerts'

// Components
import MonitorTypeIcon from '@/alerting/MonitorTypeIcon.vue'
import MonitorStateAvatar from '@/alerting/MonitorStateAvatar.vue'
import MonitorYamlDialog from '@/alerting/MonitorYamlDialog.vue'

// Misc
import { Monitor, MonitorType, MonitorState } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorsTableRow',
  components: { MonitorTypeIcon, MonitorStateAvatar, MonitorYamlDialog },

  props: {
    monitor: {
      type: Object as PropType<Monitor>,
      required: true,
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)
    const activeMonitor = shallowRef<Monitor>()
    const dialog = shallowRef(false)
    const confirm = useConfirm()
    const monitorMan = useMonitorManager()

    const routeForOpenAlerts = computed(() => {
      return routeForAlerts(AlertStatus.Open)
    })

    const routeForClosedAlerts = computed(() => {
      return routeForAlerts(AlertStatus.Closed)
    })

    function routeForAlerts(status: AlertStatus) {
      return {
        name: 'AlertList',
        query: {
          q: 'monitor:' + props.monitor.id,
          'attrs.alert_status': status,
        },
      }
    }

    function activateMonitor() {
      monitorMan.activate(props.monitor).then(() => {
        ctx.emit('change', props.monitor)
      })
    }

    function pauseMonitor(monitor: Monitor) {
      monitorMan.pause(monitor).then(() => {
        ctx.emit('change', props.monitor)
      })
    }

    function deleteMonitor() {
      confirm
        .open('Delete monitor', `Do you really want to delete "${props.monitor.name}" monitor?`)
        .then(() => monitorMan.del(props.monitor))
        .then((monitor) => ctx.emit('change', monitor))
        .catch(() => {})
    }

    function openMonitorDialog(monitor: Monitor) {
      activeMonitor.value = monitor
      dialog.value = true
    }

    return {
      MonitorType,
      MonitorState,

      menu,
      dialog,
      activeMonitor,
      routeForMonitor,
      routeForOpenAlerts,
      routeForClosedAlerts,

      monitorMan,
      activateMonitor,
      pauseMonitor,
      deleteMonitor,
      openMonitorDialog,
    }
  },
})
