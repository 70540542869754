
import { cloneDeep } from 'lodash-es'
import { defineComponent, shallowRef, reactive, computed, watch, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { injectForceReload } from '@/use/force-reload'
import { useConfirm } from '@/use/confirm'
import { useGridRow, useGridRowManager } from '@/metrics/use-dashboards'

// Components
import DialogCard from '@/components/DialogCard.vue'
import GridStackCard from '@/metrics/GridStackCard.vue'
import GridRowForm from '@/metrics/GridRowForm.vue'

// Misc
import { GridRow } from '@/metrics/types'

export default defineComponent({
  name: 'GridRowCard',
  components: { DialogCard, GridStackCard, GridRowForm },

  props: {
    row: {
      type: Object as PropType<GridRow>,
      required: true,
    },
  },

  setup(props, ctx) {
    const route = useRoute()

    const expanded = shallowRef(false)
    function toggle() {
      expanded.value = !expanded.value
    }
    watch(
      () => props.row.expanded,
      (expandedValue) => {
        expanded.value = expandedValue
      },
      { immediate: true },
    )

    const forceReload = injectForceReload()
    const gridRow = useGridRow(() => {
      if (!expanded.value) {
        return null
      }

      const { projectId, dashId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/dashboards/${dashId}/rows/${props.row.id}`,
        params: {
          ...forceReload.params,
        },
      }
    })
    watch(
      () => props.row,
      () => {
        gridRow.reload()
      },
      { flush: 'sync' },
    )

    const gridItems = computed(() => {
      if (gridRow.status.hasData()) {
        return gridRow.items
      }
      return props.row.items
    })

    const confirm = useConfirm()
    const gridRowMan = useGridRowManager()
    function deleteRow() {
      confirm.open('Delete', `Do you really want to delete the "${props.row.title}" row?`).then(
        () => {
          gridRowMan.delete(props.row).then(() => {
            ctx.emit('change')
          })
        },
        () => {},
      )
    }
    function moveUp() {
      gridRowMan.moveUp(props.row).then(() => {
        ctx.emit('change')
      })
    }
    function moveDown() {
      gridRowMan.moveDown(props.row).then(() => {
        ctx.emit('change')
      })
    }

    const dialog = shallowRef(false)
    function openDialog() {
      dialog.value = true
    }

    return {
      gridRow,
      gridItems,

      expanded,
      toggle,

      dialog,
      openDialog,

      gridRowMan,
      deleteRow,
      moveUp,
      moveDown,

      cloneDeep,
      reactive,
    }
  },
})
