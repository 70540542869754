
import { defineComponent } from 'vue'

// Composable
import { useTitle } from '@vueuse/core'
import { useDataUsage } from '@/org/use-data-usage'

// Components
import DataUsageChart from '@/org/DataUsageChart.vue'

export default defineComponent({
  name: 'DataUsage',
  components: { DataUsageChart },

  setup() {
    useTitle('Data Usage')

    const usage = useDataUsage()

    return {
      usage,
    }
  },
})
