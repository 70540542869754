
import { defineComponent } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'

// Components
import CheatsheetCard from '@/metrics/CheatsheetCard.vue'

export default defineComponent({
  name: 'Cheatcheet',
  components: { CheatsheetCard },

  setup() {
    useTitle('Querying Metrics Cheatsheet')
    return {}
  },
})
