
import { defineComponent, PropType } from 'vue'

// Composables
import { useDateRangeFrom, UseDateRange } from '@/use/date-range'

// Components
import DashTableForm from '@/metrics/DashTableForm.vue'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashTableFormDialog',
  components: { DashTableForm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const internalDateRange = useDateRangeFrom(props.dateRange)
    return { internalDateRange }
  },
})
