
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'SinglePanel',

  props: {
    title: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const expandedInternal = shallowRef(props.expanded)

    return { expandedInternal }
  },
})
