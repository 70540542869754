
import { defineComponent, computed, reactive } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRouterOnly, useSyncQueryParams } from '@/use/router'
import { useDateRange } from '@/use/date-range'
import { useProject } from '@/org/use-projects'
import { useErrorMonitor, useMonitorManager } from '@/alerting/use-monitors'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import MonitorErrorForm from '@/alerting/MonitorErrorForm.vue'
import MonitorStateAvatar from '@/alerting/MonitorStateAvatar.vue'

// Misc
import { MonitorState } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorErrorShow',
  components: {
    DateRangePicker,
    MonitorErrorForm,
    MonitorStateAvatar,
  },

  setup() {
    useTitle('Metrics Monitor')
    const router = useRouterOnly()
    const dateRange = useDateRange()
    const project = useProject()

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
        exact: true,
      })

      bs.push({
        text: 'Monitors',
        to: {
          name: 'MonitorList',
        },
        exact: true,
      })

      bs.push({ text: 'Edit error monitor' })

      return bs
    })

    const monitor = useErrorMonitor()
    const monitorMan = useMonitorManager()

    useSyncQueryParams({
      fromQuery(queryParams) {
        dateRange.parseQueryParams(queryParams)
      },
      toQuery() {
        return {
          ...dateRange.queryParams(),
        }
      },
    })

    function onSave() {
      redirectToMonitors()
    }
    function onCancel() {
      redirectToMonitors()
    }
    function redirectToMonitors() {
      router.push({ name: 'MonitorList' })
    }

    function activateMonitor() {
      if (!monitor.data) {
        return
      }
      monitorMan.activate(monitor.data).then(() => {
        monitor.reload()
      })
    }

    function pauseMonitor() {
      if (!monitor.data) {
        return
      }
      monitorMan.pause(monitor.data).then(() => {
        monitor.reload()
      })
    }

    return {
      MonitorState,

      dateRange,
      breadcrumbs,

      monitor,

      onSave,
      onCancel,

      monitorMan,
      activateMonitor,
      pauseMonitor,
      reactive,
    }
  },
})
