
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Composables
import { useNotifChannels } from '@/alerting/use-notif-channels'
import { useMonitorManager } from '@/alerting/use-monitors'
import { emptyAttrMatcher } from '@/use/attr-matcher'

// Components
import AttrMatcher from '@/components/AttrMatcher.vue'

// Misc
import { ErrorMonitor } from '@/alerting/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'MonitorErrorForm',
  components: { AttrMatcher },

  props: {
    monitor: {
      type: Object as PropType<ErrorMonitor>,
      required: true,
    },
  },

  setup(props, ctx) {
    const channels = useNotifChannels(() => {
      return {}
    })

    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = {
      name: [requiredRule],
    }

    const monitorMan = useMonitorManager()

    watch(
      () => props.monitor.params.matchers.length,
      (length) => {
        if (!length) {
          props.monitor.params.matchers = [emptyAttrMatcher()]
        }
      },
      { immediate: true },
    )

    function submit() {
      save().then(() => {
        ctx.emit('click:save')
      })
    }

    function save() {
      if (!form.value.validate()) {
        return Promise.reject()
      }

      const data = {
        ...props.monitor,
        params: {
          ...props.monitor.params,
          matchers: props.monitor.params.matchers.filter((m) => m.attr && m.value),
        },
      }

      if (props.monitor.id) {
        return monitorMan.updateErrorMonitor(data)
      }
      return monitorMan.createErrorMonitor(data)
    }

    function addMatcher() {
      props.monitor.params.matchers.push(emptyAttrMatcher())
    }

    function removeMatcher(i: number) {
      props.monitor.params.matchers.splice(i, 1)
    }

    return {
      channels,

      form,
      isValid,
      rules,
      submit,

      monitorMan,
      addMatcher,
      removeMatcher,
    }
  },
})
