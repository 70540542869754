
import { defineComponent, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import GridItemGauge from '@/metrics/GridItemGauge.vue'
import GridItemCard from '@/metrics/GridItemCard.vue'
import GridItemChart from '@/metrics/GridItemChart.vue'
import GridItemTable from '@/metrics/GridItemTable.vue'
import GridItemHeatmap from '@/metrics/GridItemHeatmap.vue'

// Misc
import { Dashboard, GridItem, GridItemType } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemSwitch',
  components: {
    GridItemGauge,
    GridItemCard,
    GridItemChart,
    GridItemTable,
    GridItemHeatmap,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    gridItem: {
      type: Object as PropType<GridItem>,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return { GridItemType }
  },
})
