
import { truncate } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { UseOrder } from '@/use/order'
import { UseUql } from '@/use/uql'
import { TimeseriesGroup, ColumnInfo } from '@/tracing/use-timeseries'

// Components
import PagedSpansCardLazy from '@/tracing/PagedSpansCardLazy.vue'

// Misc
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'TimeseriesGroupsTable',
  components: { PagedSpansCardLazy },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    uql: {
      type: Object as PropType<UseUql>,
      default: undefined,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },

    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    groups: {
      type: Array as PropType<TimeseriesGroup[]>,
      required: true,
    },
    groupingColumns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
    metricColumns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
    isSpan: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const headers = computed(() => {
      const headers = []
      for (let col of props.groupingColumns) {
        headers.push({ text: col.name, value: col.name, sortable: true, align: 'start' })
      }
      for (let col of props.metricColumns) {
        headers.push({ text: col.name, value: '_avg_' + col.name, sortable: true, align: 'end' })
      }
      headers.push({ text: '', value: 'data-table-expand', sortable: false })
      return headers
    })

    const hasSelected = computed((): boolean => {
      return props.groups.some((item) => item._selected)
    })

    function toggleSelected() {
      const selected = !hasSelected.value
      props.groups.forEach((item) => {
        item._selected = selected
      })
    }

    return {
      AttrKey,

      headers,

      hasSelected,
      toggleSelected,
      truncate,
    }
  },
})
