
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import SpanAttrsTableRow from '@/tracing/SpanAttrsTableRow.vue'

// Utitlies
import { AttrMap } from '@/models/span'
import { AttrKey, isEventSystem } from '@/models/otel'

export default defineComponent({
  name: 'SpanAttrsTable',
  components: { SpanAttrsTableRow },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object as PropType<AttrMap>,
      required: true,
    },
    attrKeys: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  setup(props) {
    const isEvent = computed((): boolean => {
      return isEventSystem(props.system)
    })

    return {
      AttrKey,
      isEvent,
    }
  },
})
