
import { defineComponent, shallowRef, reactive, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useGridRowManager } from '@/metrics/use-dashboards'

// Components
import GridItemFormSwitch from '@/metrics/GridItemFormSwitch.vue'

// Misc
import {
  emptyBaseGridItem,
  defaultChartLegend,
  Dashboard,
  DashKind,
  GridItem,
  GridItemType,
  GaugeGridItem,
  ChartGridItem,
  TableGridItem,
  HeatmapGridItem,
  ChartKind,
} from '@/metrics/types'

export default defineComponent({
  name: 'NewGridItemMenu',
  components: { GridItemFormSwitch },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    dashKind: {
      type: String as PropType<DashKind>,
      required: true,
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)
    const gridRowMan = useGridRowManager()

    function addRow() {
      gridRowMan.create({ title: 'Row title', expanded: true }).then(() => {
        ctx.emit('change')
      })
    }

    function newGauge(): GaugeGridItem {
      return reactive({
        ...emptyBaseGridItem(),

        dashId: props.dashboard.id,
        dashKind: props.dashKind,

        type: GridItemType.Gauge,
        params: {
          metrics: [],
          query: '',
          columnMap: {},
          template: '',
          valueMappings: [],
        },
      })
    }

    function newChart(): ChartGridItem {
      return reactive({
        ...emptyBaseGridItem(),

        dashId: props.dashboard.id,
        dashKind: props.dashKind,

        type: GridItemType.Chart,
        params: {
          chartKind: ChartKind.Line,
          metrics: [],
          query: '',
          columnMap: {},
          timeseriesMap: {},
          connectNulls: false,
          legend: defaultChartLegend(),
        },
      })
    }

    function newTable(): TableGridItem {
      return reactive({
        ...emptyBaseGridItem(),

        dashId: props.dashboard.id,
        dashKind: props.dashKind,

        type: GridItemType.Table,
        params: {
          metrics: [],
          query: '',
          columnMap: {},
          itemsPerPage: 5,
          denseTable: false,
        },
      })
    }

    function newHeatmap(): HeatmapGridItem {
      return reactive({
        ...emptyBaseGridItem(),

        dashId: props.dashboard.id,
        dashKind: props.dashKind,

        type: GridItemType.Heatmap,
        params: {
          metric: '',
          unit: '',
          query: '',
        },
      })
    }

    const dialog = shallowRef(false)
    const activeGridItem = shallowRef<GridItem>()
    function openDialog(gridItem: GridItem) {
      activeGridItem.value = gridItem
      dialog.value = true
    }

    return {
      DashKind,

      menu,

      addRow,
      newGauge,
      newChart,
      newTable,
      newHeatmap,

      dialog,
      activeGridItem,
      openDialog,
    }
  },
})
