
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { usePager } from '@/use/pager'
import { Facet, UseFacetedSearch } from '@/use/faceted-search'

export default defineComponent({
  name: 'SearchFacetList',

  props: {
    facetedSearch: {
      type: Object as PropType<UseFacetedSearch>,
      required: true,
    },
    facet: {
      type: Object as PropType<Facet>,
      required: true,
    },
  },

  setup(props) {
    const searchInput = shallowRef('')
    const pager = usePager()

    const filteredItems = computed(() => {
      if (!searchInput.value) {
        return props.facet.items
      }

      return fuzzyFilter(props.facet.items, searchInput.value, { key: 'value' })
    })

    const pagedItems = computed(() => {
      return filteredItems.value.slice(pager.pos.start, pager.pos.end)
    })

    watch(
      () => filteredItems.value.length,
      (numItem) => {
        pager.numItem = numItem
      },
      { immediate: true },
    )

    return {
      searchInput,
      pagedItems,
      pager,
    }
  },
})
