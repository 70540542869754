
import { defineComponent, shallowRef } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useSyncQueryParams } from '@/use/router'
import { injectForceReload } from '@/use/force-reload'
import { useOrder } from '@/use/order'
import { useAnnotations, emptyAnnotation } from '@/org/use-annotations'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'
import AnnotationsTable from '@/alerting/AnnotationsTable.vue'
import AnnotationForm from '@/alerting/AnnotationForm.vue'

export default defineComponent({
  name: 'AnnotationList',
  components: {
    ForceReloadBtn,
    AnnotationsTable,
    AnnotationForm,
  },

  setup() {
    useTitle('Annotations')
    const dialog = shallowRef(false)
    const forceReload = injectForceReload()
    const order = useOrder()

    const annotations = useAnnotations(() => {
      return {
        ...forceReload.params,
        ...order.axiosParams,
      }
    })

    useSyncQueryParams({
      fromQuery(queryParams) {
        queryParams.setDefault('sort_by', 'createdAt')
        queryParams.setDefault('sort_desc', true)

        order.parseQueryParams(queryParams)
      },
      toQuery() {
        return {
          ...order.queryParams(),
        }
      },
    })

    return {
      dialog,

      order,
      annotations,

      emptyAnnotation,
    }
  },
})
