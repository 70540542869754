
import { defineComponent, shallowRef, nextTick, watch, PropType } from 'vue'

// Composables
import { useDateRange, UseDateRange } from '@/use/date-range'
import { useRoute } from '@/use/router'
import { UsePager } from '@/use/pager'
import { UseOrder } from '@/use/order'
import { useAnnotations } from '@/org/use-annotations'

// Components
import SpanCard from '@/tracing/SpanCard.vue'
import ThOrder from '@/components/ThOrder.vue'
import SpanChips from '@/tracing/SpanChips.vue'

// Misc
import { AttrKey } from '@/models/otel'
import { Span } from '@/models/span'

export default defineComponent({
  name: 'SpansTable',
  components: {
    ThOrder,
    SpanCard,
    SpanChips,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    spans: {
      type: Array as PropType<Span[]>,
      required: true,
    },
    pager: {
      type: Object as PropType<UsePager>,
      required: true,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isSpan: {
      type: Boolean,
      required: true,
    },
    showSystem: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute()

    function systemRoute(span: Span) {
      return {
        query: {
          ...route.value.query,
          system: span.system,
        },
      }
    }

    function onSortBy() {
      nextTick(() => {
        props.order.desc = true
      })
    }

    // Dialog
    //-------

    const internalDateRange = useDateRange()
    useAnnotations(() => {
      return {
        ...internalDateRange.axiosParams(),
      }
    })

    const dialog = shallowRef(false)
    const activeSpan = shallowRef<Span>()

    watch(route, () => {
      dialog.value = false
    })

    watch(dialog, (dialog) => {
      if (dialog) {
        internalDateRange.syncWith(props.dateRange)
      }
    })

    function showSpan(span: Span) {
      activeSpan.value = span
      dialog.value = true
    }

    return {
      internalDateRange,
      AttrKey,
      dialog,
      activeSpan,

      systemRoute,
      onSortBy,

      showSpan,
    }
  },
})
