
import { defineComponent, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql, joinQuery, injectQueryStore } from '@/use/uql'
import { useMetrics, useActiveMetrics } from '@/metrics/use-metrics'
import { useHeatmapQuery } from '@/metrics/use-query'

// Components
import GridItemFormPanes from '@/metrics/GridItemFormPanes.vue'
import SinglePanel from '@/components/SinglePanel.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import HeatmapChart from '@/components/HeatmapChart.vue'

// Misc
import { UNITS } from '@/util/fmt'
import { requiredRule } from '@/util/validation'
import { HeatmapGridItem, Instrument } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemHeatmapForm',
  components: { GridItemFormPanes, SinglePanel, MetricsQueryBuilder, HeatmapChart },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    gridItem: {
      type: Object as PropType<HeatmapGridItem>,
      required: true,
    },
  },

  setup(props, ctx) {
    const rules = { metric: [requiredRule], title: [requiredRule] }

    const uql = useUql()
    const { where } = injectQueryStore()
    const activeMetrics = useActiveMetrics(
      computed(() => {
        if (!props.gridItem.params.metric) {
          return []
        }
        return [
          {
            name: props.gridItem.params.metric,
            alias: props.gridItem.params.metric,
            query: joinQuery([props.gridItem.params.query, where.value]),
          },
        ]
      }),
    )

    const unitItems = computed(() => {
      return UNITS.map((unit) => {
        return { value: unit, text: unit || 'none' }
      })
    })

    const metrics = useMetrics(() => {
      return { instrument: Instrument.Histogram }
    })

    watch(
      () => props.gridItem.params.metric,
      (metricName) => {
        const metric = metrics.items.find((metric) => metric.name === metricName)
        if (metric) {
          props.gridItem.params.unit = metric.unit
        }
      },
    )

    const heatmapQuery = useHeatmapQuery(() => {
      if (!props.gridItem.params.metric) {
        return undefined
      }

      return {
        ...props.dateRange.axiosParams(),
        metric: props.gridItem.params.metric,
        alias: props.gridItem.params.metric,
        query: props.gridItem.params.query,
      }
    })

    watch(
      () => props.gridItem.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )
    watch(
      () => uql.query,
      (query) => {
        props.gridItem.params.query = query
      },
    )

    watch(
      () => heatmapQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
    )

    return {
      Instrument,

      rules,
      unitItems,

      metrics,
      heatmapQuery,

      uql,
      activeMetrics,
    }
  },
})
