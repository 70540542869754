
import { defineComponent, ref, computed, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { createQueryEditor, injectQueryStore } from '@/use/uql'
import { injectAnnotations } from '@/org/use-annotations'

// Components
import NewMonitorMenu from '@/tracing/NewMonitorMenu.vue'
import SpanAttrs from '@/tracing/SpanAttrs.vue'
import EventPanels from '@/tracing/EventPanels.vue'
import GroupInfoCard from '@/tracing/GroupInfoCard.vue'

// Misc
import { AttrKey, isSpanSystem } from '@/models/otel'
import { Span } from '@/models/span'

export default defineComponent({
  name: 'SpanBodyCard',
  components: {
    NewMonitorMenu,
    SpanAttrs,
    EventPanels,
    GroupInfoCard,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    span: {
      type: Object as PropType<Span>,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute()
    const { where } = injectQueryStore()
    const activeTab = ref('attrs')

    const isSpan = computed((): boolean => {
      return isSpanSystem(props.span.system)
    })

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.span.system,
        group_id: props.span.groupId,
        query: where.value,
      }
    })

    const traceRoute = computed(() => {
      if (props.span.standalone) {
        return null
      }
      if (route.value.name === 'TraceShow' && route.value.params.traceId === props.span.traceId) {
        return null
      }

      return {
        name: 'TraceShow',
        params: {
          traceId: props.span.traceId,
        },
      }
    })

    const spanGroupRoute = computed(() => {
      switch (route.value.name) {
        case 'SpanList':
        case 'SpanGroupList':
          return undefined
      }

      return {
        name: 'SpanList',
        query: {
          ...props.dateRange.queryParams(),
          system: props.span.system,
          query: createQueryEditor()
            .exploreAttr(AttrKey.spanGroupId, isSpan.value)
            .where(AttrKey.spanGroupId, '=', props.span.groupId)
            .toString(),
        },
      }
    })

    return {
      AttrKey,
      activeTab,
      isSpan,

      annotations: injectAnnotations(),

      axiosParams,

      spanGroupRoute,
      traceRoute,
    }
  },
})
