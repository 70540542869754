
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { Project } from '@/org/use-projects'

export default defineComponent({
  name: 'ProjectMenu',

  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },

  setup(props) {
    const params = computed(() => {
      return { projectId: props.project.id }
    })
    return { params }
  },
})
