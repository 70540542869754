
import { defineComponent, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useGroups } from '@/tracing/use-explore-spans'

export default defineComponent({
  name: 'PagedGroupsCardLazy',

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    systems: {
      type: Array as PropType<string[]>,
      required: true,
    },
    plottedColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showPlottedColumnItems: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const groups = useGroups(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.systems,
        query: props.query,
      }
    })

    return {
      groups,
    }
  },
})
