
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { Annotation } from '@/org/use-annotations'
import { UseOrder } from '@/use/order'

// Components
import AnnotationsTableRow from '@/alerting/AnnotationsTableRow.vue'

export default defineComponent({
  name: 'AnnotationsTable',
  components: { AnnotationsTableRow },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    annotations: {
      type: Array as PropType<Annotation[]>,
      required: true,
    },
  },

  setup(props) {
    const headers = computed(() => {
      const headers = []
      headers.push({ text: 'Name', value: 'name', sortable: true, align: 'start' })
      headers.push({ text: 'Attributes', value: 'attrs', sortable: false, align: 'start' })
      headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'center' })
      headers.push({ text: 'Created at', value: 'createdAt', sortable: true, align: 'end' })
      return headers
    })

    return {
      headers,
    }
  },
})
