
import { defineComponent, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql, useQueryStore, provideQueryStore } from '@/use/uql'
import { useDashboardManager } from '@/metrics/use-dashboards'

// Components
import DashQueryBuilder from '@/metrics/query/DashQueryBuilder.vue'
import GridRowCard from '@/metrics/GridRowCard.vue'
import GridItemAny from '@/metrics/GridItemAny.vue'

// Misc
import { Dashboard, GridRow, GridItemType, DashKind } from '@/metrics/types'

export default defineComponent({
  name: 'DashdGrid',
  components: {
    DashQueryBuilder,
    GridRowCard,
    GridItemAny,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    gridRows: {
      type: Array as PropType<GridRow[]>,
      required: true,
    },
    gridMetrics: {
      type: Array as PropType<string[]>,
      required: true,
    },
    gridQuery: {
      type: String,
      default: '',
    },
  },

  setup(props, ctx) {
    const uql = useUql()
    provideQueryStore(useQueryStore(uql))
    watch(
      () => props.gridQuery,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    const dashMan = useDashboardManager()
    const isGridQueryDirty = computed(() => {
      return uql.query !== props.gridQuery
    })
    function saveGridQuery() {
      dashMan.updateGrid({ id: props.dashboard.id, gridQuery: uql.query }).then(() => {
        ctx.emit('change')
      })
    }

    return {
      DashKind,
      GridItemType,

      uql,

      dashMan,
      isGridQueryDirty,
      saveGridQuery,
    }
  },
})
