
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Misc
import { StyledTimeseries, LegendValue } from '@/metrics/types'
import { truncateMiddle } from '@/util/string'

export default defineComponent({
  name: 'ChartLegendList',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeseries: {
      type: Array as PropType<StyledTimeseries[]>,
      required: true,
    },
    values: {
      type: Array as PropType<LegendValue[]>,
      default: () => [LegendValue.Avg, LegendValue.Last, LegendValue.Min, LegendValue.Max],
    },
    direction: {
      type: String,
      default: 'row',
    },
  },

  setup(props, ctx) {
    const selectedTimeseries = shallowRef<StyledTimeseries[]>([])
    watch(
      () => props.timeseries,
      (timeseries) => {
        selectedTimeseries.value = timeseries
      },
      { immediate: true },
    )
    watch(
      selectedTimeseries,
      (selectedTimeseries) => {
        ctx.emit('current-items', selectedTimeseries)
      },
      { immediate: true },
    )

    function toggle(ts: StyledTimeseries) {
      const items = selectedTimeseries.value.slice()
      const index = items.findIndex((item) => item.id === ts.id)
      if (index >= 0) {
        items.splice(index, 1)
      } else {
        items.push(ts)
      }
      selectedTimeseries.value = items
    }

    function isSelected(ts: StyledTimeseries): boolean {
      const index = selectedTimeseries.value.findIndex((item) => item.id === ts.id)
      return index >= 0
    }

    return {
      selectedTimeseries,
      toggle,
      isSelected,

      truncateMiddle,
    }
  },
})
