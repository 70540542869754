
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { useSnackbar } from '@/use/snackbar'
import { useYamlDashboard, useDashboardManager } from '@/metrics/use-dashboards'

// Misc
import { Dashboard } from '@/metrics/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'DashboardEditYamlForm',

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const isValid = shallowRef(true)
    const yaml = shallowRef('')
    const rules = {
      yaml: [requiredRule],
    }

    const snackbar = useSnackbar()
    const route = useRoute()
    const dashMan = useDashboardManager()
    const dash = useYamlDashboard(() => {
      if (!props.dashboard.id) {
        return
      }

      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/dashboards/${props.dashboard.id}/yaml`,
      }
    })

    watch(
      () => dash.yaml,
      (dashYaml) => {
        yaml.value = dashYaml
      },
      { immediate: true },
    )

    function saveYaml() {
      dashMan.updateYaml(yaml.value).then(() => {
        snackbar.notifySuccess(`The dashboard has beed successfully updated from the YAML`)
        ctx.emit('updated')
      })
    }

    return {
      isValid,
      rules,
      yaml,
      dash,
      dashMan,
      saveYaml,
    }
  },
})
