
import { defineComponent, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useProject } from '@/org/use-projects'
import { useWebhookNotifChannel } from '@/alerting/use-notif-channels'

// Components
import NotifChannelWebhookForm from '@/alerting/NotifChannelWebhookForm.vue'

export default defineComponent({
  name: 'ChannelShowWebhook',
  components: { NotifChannelWebhookForm },

  setup() {
    useTitle('Webhook Channel')
    const route = useRoute()
    const project = useProject()

    const channel = useWebhookNotifChannel(() => {
      const { projectId, channelId } = route.value.params
      return {
        url: `/internal/v1/projects/${projectId}/notification-channels/webhook/${channelId}`,
      }
    })

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
        exact: true,
      })

      bs.push({
        text: 'Channels',
        to: {
          name: 'NotifChannelList',
        },
        exact: true,
      })

      bs.push({ text: 'Webhook' })

      return bs
    })

    return { channel, breadcrumbs }
  },
})
