
import { truncate } from 'lodash-es'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AnnotationAttrs',

  props: {
    attrs: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      truncate,
    }
  },
})
