
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseUql } from '@/use/uql'

// Misc
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'DurationFilterMenu',

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
  },

  setup(props) {
    const menu = shallowRef(false)
    const gte = shallowRef()
    const lt = shallowRef()

    const isValid = computed((): boolean => {
      return gte.value || lt.value
    })

    function addFilter() {
      if (!isValid.value) {
        menu.value = false
        return
      }

      const editor = props.uql.createEditor()

      if (gte.value) {
        editor.replaceOrPush(
          /^where\s+span\.duration\s+>=\s.+$/i,
          `where ${AttrKey.spanDuration} >= ${gte.value}ms`,
        )
      }
      if (lt.value) {
        editor.replaceOrPush(
          /^where\s+span\.duration\s+<\s.+$/i,
          `where ${AttrKey.spanDuration} < ${lt.value}ms`,
        )
      }

      props.uql.commitEdits(editor)

      menu.value = false
      gte.value = undefined
      lt.value = undefined
    }

    return { menu, gte, lt, isValid, addFilter }
  },
})
