
import { defineComponent, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { usePercentiles } from '@/tracing/use-percentiles'

// Components
import PercentilesChart from '@/components/PercentilesChart.vue'
import EventRateChart from '@/components/EventRateChart.vue'
import { MarkPoint } from '@/util/chart'

// Misc
import { Annotation } from '@/org/types'

export default defineComponent({
  name: 'PercentilesChartLazy',
  components: { PercentilesChart, EventRateChart },

  props: {
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    markPoint: {
      type: Object as PropType<MarkPoint>,
      default: undefined,
    },
    annotations: {
      type: Array as PropType<Annotation[]>,
      default: () => [],
    },
  },

  setup(props) {
    const route = useRoute()

    const percentiles = usePercentiles(() => {
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/tracing/${projectId}/percentiles`,
        params: props.axiosParams,
        cache: true,
      }
    })

    return { percentiles }
  },
})
