
import { defineComponent, ref, computed, watch, PropType } from 'vue'

// Composables
import { usePager } from '@/use/pager'
import { Item } from '@/components/facet/types'

export default defineComponent({
  name: 'FacetItemBody',

  props: {
    value: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },

  setup(props, ctx) {
    const pager = usePager()
    const values = ref<string[]>([])

    const pagedItems = computed(() => {
      return props.items.slice(pager.pos.start, pager.pos.end)
    })

    watch(
      () => props.value,
      (value) => {
        values.value = value ?? []
      },
      { immediate: true },
    )

    watch(
      () => props.items.length,
      (numItem) => {
        pager.numItem = numItem
      },
      { immediate: true },
    )

    function toggle(itemValue: string) {
      const selected = values.value.slice()
      const index = selected.indexOf(itemValue)
      if (index >= 0) {
        selected.splice(index, 1)
      } else {
        selected.push(itemValue)
      }
      ctx.emit('input', selected)
    }

    function toggleOne(itemValue: string) {
      let selected = [itemValue]
      if (values.value.length === 1 && values.value.includes(itemValue)) {
        selected = []
      }
      ctx.emit('input', selected)
    }

    return { pager, values, pagedItems, toggle, toggleOne }
  },
})
