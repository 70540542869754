
import { defineComponent, shallowRef, PropType } from 'vue'

// Components
import BtnSelectMenu from '@/components/BtnSelectMenu.vue'

// Misc
import { ValueMapping, MappingOp } from '@/metrics/types'

export default defineComponent({
  name: 'ValueMappingRow',
  components: { BtnSelectMenu },

  props: {
    value: {
      type: Object as PropType<ValueMapping>,
      required: true,
    },
  },

  setup() {
    const dialog = shallowRef(false)

    const mappingOpItems = [
      { text: 'any', value: MappingOp.Any },
      { text: '==', value: MappingOp.Equal },
      { text: '>', value: MappingOp.Gt },
      { text: '>=', value: MappingOp.Gte },
      { text: '<', value: MappingOp.Lt },
      { text: '<=', value: MappingOp.Lte },
    ]

    return { MappingOp, dialog, mappingOpItems }
  },
})
