
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { AttrMatcher, AttrMatcherOp } from '@/use/attr-matcher'

// Misc
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'SamplingRuleMatcherRow',

  props: {
    matcher: {
      type: Object as PropType<AttrMatcher>,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const opItems = [
      { text: '==', value: AttrMatcherOp.Equal },
      { text: '!=', value: AttrMatcherOp.NotEqual },
    ]
    const rules = computed(() => {
      const rules: Record<string, any> = {
        attr: [],
        value: [],
      }
      if (props.required) {
        rules.attr.push(requiredRule)
        rules.value.push(requiredRule)
      }
      return rules
    })

    return { opItems, rules }
  },
})
