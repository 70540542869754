
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QuickSearch',

  props: {
    value: {
      type: String,
      default: '',
    },
  },
})
