
import { defineComponent, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import { useAnnotationManager, Annotation } from '@/org/use-annotations'

// Components
import AnnotationAttrs from '@/alerting/AnnotationAttrs.vue'

export default defineComponent({
  name: 'AnnotationsTableRow',
  components: { AnnotationAttrs },

  props: {
    annotation: {
      type: Object as PropType<Annotation>,
      required: true,
    },
  },

  setup(props, ctx) {
    const confirm = useConfirm()
    const annotationMan = useAnnotationManager()

    function deleteAnnotation() {
      confirm
        .open(
          'Delete annotation',
          `Do you really want to delete "${props.annotation.name}" annotation?`,
        )
        .then(() => annotationMan.del(props.annotation))
        .then((annotation) => ctx.emit('change', annotation))
        .catch(() => {})
    }

    return {
      annotationMan,

      deleteAnnotation,
    }
  },
})
