
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import SpanAttrValue from '@/tracing/SpanAttrValue.vue'
import SpanAttrValues from '@/tracing/SpanAttrValues.vue'

export default defineComponent({
  name: 'SpanAttrsTableRow',
  components: { SpanAttrValue, SpanAttrValues },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    attrKey: {
      type: String,
      required: true,
    },
    attrValue: {
      type: undefined,
      required: true,
    },
  },

  setup(props) {
    const isExpanded = shallowRef(false)

    function expand(flag: boolean) {
      isExpanded.value = flag
    }

    const attrValues = computed(() => {
      if (Array.isArray(props.attrValue)) {
        return props.attrValue
      }
      return [props.attrValue]
    })

    return { isExpanded, expand, attrValues }
  },
})
