
import { defineComponent, computed } from 'vue'
import vuetify from '@/plugins/vuetify'

export default defineComponent({
  name: 'PageToolbar',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: undefined,
    },
  },

  setup(props) {
    const fluid = computed(() => {
      if (props.fluid !== undefined) {
        return props.fluid
      }
      return vuetify.framework.breakpoint.mdAndDown
    })

    return { localFluid: fluid }
  },
})
