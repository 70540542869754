
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useStorage } from '@/use/local-storage'
import { UseUql } from '@/use/uql'

// Components
import FacetList from '@/components/facet/FacetList.vue'

export default defineComponent({
  name: 'DashWhereBtn',
  components: { FacetList },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },

  setup(props) {
    const drawer = shallowRef(false)
    const width = useStorage('tracing-width', 400)
    const temporary = useStorage('tracing-temporary', true)

    const facetParams = computed(() => {
      if (!drawer.value) {
        return null
      }
      return {
        ...props.axiosParams,
        query: props.uql.whereQuery,
      }
    })

    function onClickOutside() {
      if (temporary.value) {
        drawer.value = false
      }
    }

    function closeConditional() {
      return drawer.value
    }

    return {
      facetParams,

      drawer,
      width,
      temporary,

      onClickOutside,
      closeConditional,
    }
  },
})
