
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { UseDateRange } from '@/use/date-range'
import { useExploreMetrics, ExploredMetric } from '@/metrics/use-metrics'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import MetricsTable from '@/metrics/MetricsTable.vue'
import ExploreMetric from '@/metrics/ExploreMetric.vue'

// Misc
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'Explore',
  components: { DateRangePicker, MetricsTable, ExploreMetric },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup(props) {
    useTitle('Explore Metrics')
    const route = useRoute()

    const activeAttrKeys = shallowRef<string[]>([])
    const activeInstruments = shallowRef<string[]>([])
    const activeOtelLibraries = shallowRef<string[]>([])

    const attrKeysDs = useDataSource(() => {
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/attributes`,
        params: {
          ...props.dateRange.axiosParams(),
          instrument: activeInstruments.value,
          otel_library_name: activeOtelLibraries.value,
        },
      }
    })

    const instrumentDs = useDataSource(() => {
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/attributes/${AttrKey.metricInstrument}`,
        params: {
          ...props.dateRange.axiosParams(),
          attr_key: activeAttrKeys.value,
          otel_library_name: activeOtelLibraries.value,
        },
      }
    })

    const otelLibraryDs = useDataSource(() => {
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/attributes/${AttrKey.otelLibraryName}`,
        params: {
          ...props.dateRange.axiosParams(),
          attr_key: activeAttrKeys.value,
          instrument: activeInstruments.value,
        },
      }
    })

    const metrics = useExploreMetrics(() => {
      return {
        ...props.dateRange.axiosParams(),
        attr_key: activeAttrKeys.value,
        instrument: activeInstruments.value,
        otel_library_name: activeOtelLibraries.value,
      }
    })

    const dialog = shallowRef(false)
    const activeMetric = shallowRef<ExploredMetric>()

    return {
      activeAttrKeys,
      attrKeysDs,
      activeInstruments,
      instrumentDs,
      activeOtelLibraries,
      otelLibraryDs,
      metrics,

      dialog,
      activeMetric,
    }
  },
})
