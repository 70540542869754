
import { defineComponent, PropType } from 'vue'

// Composables
import { Attr } from '@/org/use-annotations'

export default defineComponent({
  name: 'AnnotationAttrRow',

  props: {
    attr: {
      type: Object as PropType<Attr>,
      required: true,
    },
  },
})
