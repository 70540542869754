
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { UseTrace, TraceSpan } from '@/tracing/use-trace'

// Components
import SpanCard from '@/tracing/SpanCard.vue'
import SpanChips from '@/tracing/SpanChips.vue'
import TraceTimelineChildrenBars from '@/tracing/TraceTimelineChildrenBars.vue'

// Misc
import { spanName } from '@/models/span'
import { spanBarStyle } from '@/models/trace-span'
import { duration } from '@/util/fmt/duration'

export default defineComponent({
  name: 'TraceTimeline',
  components: { SpanCard, SpanChips, TraceTimelineChildrenBars },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    trace: {
      type: Object as PropType<UseTrace>,
      required: true,
    },
    rootSpanId: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const dialog = shallowRef(false)

    function showSpan(span: TraceSpan) {
      dialog.value = true
      props.trace.activeSpanId = span.id
    }

    return { dialog, showSpan, spanName, duration, spanBarStyle }
  },
})
