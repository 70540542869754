
import { defineComponent, computed } from 'vue'

// Composables
import { useProject } from '@/org/use-projects'
import { useTitle } from '@vueuse/core'
import { useAnnotation } from '@/org/use-annotations'

// Components
import AnnotationForm from '@/alerting/AnnotationForm.vue'

export default defineComponent({
  name: 'AnnotationShow',
  components: { AnnotationForm },

  setup() {
    useTitle('Annotation')

    const project = useProject()
    const annotation = useAnnotation()

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
        exact: true,
      })

      bs.push({
        text: 'Annotations',
        to: {
          name: 'AnnotationList',
        },
        exact: true,
      })

      bs.push({ text: 'Annotation' })

      return bs
    })

    return {
      annotation,
      breadcrumbs,
    }
  },
})
