
import { defineComponent } from 'vue'

// Composables
import { useSyncQueryParams } from '@/use/router'
import { useProject } from '@/org/use-projects'
import { useTitle } from '@vueuse/core'
import { useMetrics } from '@/metrics/use-metrics'
import { useMonitors } from '@/alerting/use-monitors'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'
import MonitorNewMenu from '@/alerting/MonitorNewMenu.vue'
import MonitorsTable from '@/alerting/MonitorsTable.vue'
import MonitorStateCounts from '@/alerting/MonitorStateCounts.vue'

export default defineComponent({
  name: 'MonitorList',
  components: {
    ForceReloadBtn,
    MonitorNewMenu,
    MonitorsTable,
    MonitorStateCounts,
  },

  setup() {
    useTitle('Monitors')

    const project = useProject()
    const metrics = useMetrics()
    const monitors = useMonitors()

    useSyncQueryParams({
      fromQuery(queryParams) {
        queryParams.setDefault('sort_by', 'updatedAt')
        queryParams.setDefault('sort_desc', true)

        monitors.parseQueryParams(queryParams)
      },
      toQuery() {
        const queryParams: Record<string, any> = {
          ...monitors.queryParams(),
        }

        return queryParams
      },
    })

    return {
      project,
      metrics,
      monitors,
    }
  },
})
