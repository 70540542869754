
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { createQueryEditor, injectQueryStore } from '@/use/uql'

// Misc
import { isSpanSystem, AttrKey } from '@/models/otel'
import { truncateMiddle, quote } from '@/util/string'

export default defineComponent({
  name: 'SpanAttrValue',

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    attrKey: {
      type: String,
      required: true,
    },
    attrValue: {
      type: undefined,
      required: true,
    },
  },

  setup(props) {
    const menu = shallowRef(false)
    const { query, where } = injectQueryStore()

    const menuItems = computed(() => {
      const quotedValue = quote(truncateMiddle(props.attrValue, 60))

      const items = [
        {
          text: `Group by ${props.attrKey}`,
          attrs: createLink('SpanGroupList', {
            query: createQueryEditor()
              .exploreAttr(props.attrKey, isSpanSystem(props.system))
              .add(where.value)
              .where(AttrKey.spanGroupId, '=', props.groupId)
              .where(props.attrKey, 'exists')
              .toString(),
          }),
        },
      ]

      if (query.value) {
        items.push({
          text: `${props.attrKey} = ${quotedValue}`,
          attrs: createLink(undefined, {
            query: createQueryEditor()
              .add(query.value)
              .where(props.attrKey, '=', props.attrValue)
              .toString(),
          }),
        })
      } else {
        items.push({
          text: `Groups with ${props.attrKey} = ${quotedValue}`,
          attrs: createLink('SpanGroupList', {
            query: createQueryEditor()
              .exploreAttr(AttrKey.spanGroupId, isSpanSystem(props.system))
              .where(props.attrKey, '=', props.attrValue)
              .toString(),
          }),
        })
      }

      return items
    })

    function createLink(routeName: string | undefined, queryParams: Record<string, any> = {}) {
      return {
        to: {
          name: routeName,
          query: {
            ...props.dateRange.queryParams(),
            ...queryParams,
          },
        },
        exact: true,
      }
    }

    return { menu, menuItems }
  },
})
