
import { defineComponent, shallowRef } from 'vue'

// Components
import ServiceGraphHelpCard from '@/tracing/ServiceGraphHelpCard.vue'

export default defineComponent({
  name: 'ServiceGraphHelpDialog',
  components: { ServiceGraphHelpCard },

  setup() {
    const dialog = shallowRef(false)

    return { dialog }
  },
})
