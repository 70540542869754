
import { defineComponent } from 'vue'

// Misc
import { Unit } from '@/util/fmt'

export default defineComponent({
  name: 'SystemMetricCard',

  props: {
    metric: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return { Unit }
  },
})
