
import { cloneDeep } from 'lodash-es'
import { defineComponent, reactive, shallowRef, PropType } from 'vue'

// Composables
import { useRouterOnly } from '@/use/router'
import { useConfirm } from '@/use/confirm'
import { useDashboardManager } from '@/metrics/use-dashboards'

// Components
import DashboardForm from '@/metrics/DashboardForm.vue'
import DashboardYamlCard from '@/metrics/DashboardYamlCard.vue'
import DashPinBtn from '@/metrics/DashPinBtn.vue'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashboardsTableRow',
  components: { DashboardForm, DashboardYamlCard, DashPinBtn },

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    headers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  setup(props, ctx) {
    const router = useRouterOnly()
    const menu = shallowRef(false)
    const activeDashboard = shallowRef<Dashboard>()
    const editDialog = shallowRef(false)
    const yamlDialog = shallowRef(false)
    const confirm = useConfirm()
    const dashboardMan = useDashboardManager()

    function openDashboardYamlDialog(dashboard: Dashboard) {
      activeDashboard.value = dashboard
      yamlDialog.value = true
    }

    function cloneDashboard() {
      dashboardMan.clone(props.dashboard).then((dash) => {
        ctx.emit('change', dash)

        router.push({
          name: 'DashboardTable',
          params: { dashId: String(dash.id) },
        })
      })
    }

    function deleteDashboard() {
      confirm
        .open('Delete', `Do you really want to delete "${props.dashboard.name}" dashboard?`)
        .then(() => {
          dashboardMan.delete(props.dashboard).then(() => {
            ctx.emit('change')
          })
        })
        .catch(() => {})
    }

    return {
      cloneDeep,
      reactive,

      menu,
      yamlDialog,
      editDialog,
      activeDashboard,

      dashboardMan,
      cloneDashboard,
      deleteDashboard,
      openDashboardYamlDialog,
    }
  },
})
