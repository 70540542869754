
import { format, supportedDialects } from 'sql-formatter'
import { pick } from 'lodash-es'
import { defineComponent, shallowRef, computed, PropType } from 'vue'
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useProject } from '@/org/use-projects'

// Components
import SpanAttrsTable from '@/tracing/SpanAttrsTable.vue'

// Misc
import { AttrMap } from '@/models/span'
import { AttrKey } from '@/models/otel'
import { buildPrefixes, Prefix } from '@/models/key-prefixes'
import { parseJson, prettyPrint } from '@/util/json'

const specialKeys = [AttrKey.dbStatement, AttrKey.exceptionStacktrace] as string[]

const LARGE_ATTR_THRESHOLD = 500

export default defineComponent({
  name: 'SpanAttrs',
  components: { SpanAttrsTable },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    attrs: {
      type: Object as PropType<AttrMap>,
      required: true,
    },
    system: {
      type: String,
      default: undefined,
    },
    groupId: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const activeTab = shallowRef()
    const searchInput = shallowRef('')

    const activePrefix = shallowRef<Prefix>()
    const prefixes = computed(() => {
      const keys = []
      for (let key in props.attrs) {
        if (!isBlacklistedKey(key)) {
          keys.push(key)
        }
      }
      return buildPrefixes(keys)
    })

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.system,
        group_id: props.groupId,
      }
    })

    const dbStmt = computed((): string => {
      return props.attrs[AttrKey.dbStatement] ?? ''
    })

    const sqlLanguage = computed(() => {
      const system = props.attrs[AttrKey.dbSystem]
      if (!system) {
        return 'sql'
      }

      if (supportedDialects.indexOf(system) >= 0) {
        return system
      }

      return 'sql'
    })

    const dbStmtPretty = computed((): string => {
      try {
        return format(dbStmt.value, {
          language: sqlLanguage.value,
        })
      } catch (err) {
        return ''
      }
    })

    const dbStmtJson = computed((): any => {
      const obj = parseJson(props.attrs[AttrKey.dbStatement])
      if (!obj) {
        return undefined
      }
      return prettyPrint(obj)
    })

    const exceptionStacktrace = computed((): string => {
      return props.attrs[AttrKey.exceptionStacktrace] ?? ''
    })

    const project = useProject()

    const largeAttrs = computed((): Record<string, string> => {
      const attrs: Record<string, string> = {}

      for (let key in props.attrs) {
        if (isInternalKey(key) || specialKeys.includes(key)) {
          continue
        }

        const value = props.attrs[key]

        const json = parseJson(value)
        if (json) {
          const pretty = prettyPrint(json)
          if (project.largeAttrs.includes(key) || pretty.length >= LARGE_ATTR_THRESHOLD) {
            attrs[key] = prettyPrint(json)
            continue
          }
        }

        if (project.largeAttrs.includes(key)) {
          attrs[key] = value
        }

        switch (typeof value) {
          case 'string':
            if (value.length >= LARGE_ATTR_THRESHOLD) {
              attrs[key] = value
            }
        }
      }

      return attrs
    })

    const attrKeys = computed((): string[] => {
      let keys = Object.keys(props.attrs)
      keys = keys.filter((key) => !isBlacklistedKey(key))

      if (activePrefix.value) {
        keys = activePrefix.value.keys
      }

      if (searchInput.value) {
        keys = fuzzyFilter(keys, searchInput.value)
      }

      keys.sort()

      return keys
    })

    const filteredAttrs = computed(() => {
      return pick(props.attrs, attrKeys.value)
    })

    function isBlacklistedKey(key: string): boolean {
      return isInternalKey(key) || key in largeAttrs.value || specialKeys.includes(key)
    }

    return {
      AttrKey,

      activeTab,
      searchInput,
      activePrefix,
      prefixes,

      axiosParams,

      dbStmt,
      dbStmtPretty,
      dbStmtJson,
      exceptionStacktrace,

      largeAttrs,
      attrKeys,
      filteredAttrs,
      prettyPrint,
    }
  },
})

function isInternalKey(key: string): boolean {
  return key.startsWith('_')
}
