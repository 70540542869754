
import { defineComponent, shallowRef } from 'vue'

// Components
import CheatsheetCard from '@/tracing/CheatsheetCard.vue'

export default defineComponent({
  name: 'QueryHelpDialog',
  components: { CheatsheetCard },

  setup() {
    const dialog = shallowRef(false)
    return { dialog }
  },
})
