
import { defineComponent, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { useSpan } from '@/tracing/use-spans'
import { ErrorAlert } from '@/alerting/use-alerts'

// Components
import SpanBodyCard from '@/tracing/SpanBodyCard.vue'

export default defineComponent({
  name: 'AlertCardSpan',
  components: {
    SpanBodyCard,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    alert: {
      type: Object as PropType<ErrorAlert>,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute()

    const span = useSpan(() => {
      const { projectId } = route.value.params
      const { traceId, spanId } = props.alert.params
      return {
        url: `/internal/v1/tracing/${projectId}/traces/${traceId}/${spanId}`,
        params: {
          ...props.dateRange.axiosParams(),
        },
      }
    })

    return { span }
  },
})
