
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { TraceSpan, UseTrace } from '@/tracing/use-trace'

// Misc
import { duration } from '@/util/fmt/duration'
import { spanBarStyle } from '@/models/trace-span'

export default defineComponent({
  name: 'TraceTimelineChildrenBars',

  props: {
    trace: {
      type: Object as PropType<UseTrace>,
      required: true,
    },
    span: {
      type: Object as PropType<TraceSpan>,
      required: true,
    },
    children: {
      type: Array as PropType<TraceSpan[]>,
      required: true,
    },
    hidden: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const internalChildren = computed(() => {
      return props.children.filter((child) => child.kind !== 'consumer')
    })

    return { internalChildren, duration, spanBarStyle }
  },
})
