
import { defineComponent, PropType } from 'vue'

// Composables
import { useYamlMonitor } from '@/alerting/use-monitors'

// Components
import DialogCard from '@/components/DialogCard.vue'

// Misc
import { Monitor } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorYamlDialog',

  components: { DialogCard },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    monitor: {
      type: Object as PropType<Monitor>,
      required: true,
    },
  },

  setup(props) {
    const yaml = useYamlMonitor(props.monitor.id)

    return {
      yaml,
    }
  },
})
