
import { defineComponent, shallowRef } from 'vue'

// Components
import DialogCard from '@/components/DialogCard.vue'
import MonitorYamlForm from '@/alerting/MonitorYamlForm.vue'

export default defineComponent({
  name: 'MonitorNewMenu',

  components: { DialogCard, MonitorYamlForm },

  setup() {
    const dialog = shallowRef(false)
    const menu = shallowRef(false)

    return { dialog, menu }
  },
})
