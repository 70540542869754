
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Components
import PanelSection from '@/components/PanelSection.vue'

// Misc
import { ChartKind, TimeseriesStyle } from '@/metrics/types'

export default defineComponent({
  name: 'TimeseriesStyleCard',
  components: { PanelSection },

  props: {
    chartKind: {
      type: String as PropType<ChartKind>,
      required: true,
    },
    timeseriesStyle: {
      type: Object as PropType<TimeseriesStyle>,
      required: true,
    },
    width: {
      type: Number,
      default: 400,
    },
  },

  setup(props, ctx) {
    const dialog = shallowRef(false)

    const showOpacity = computed(() => {
      return [ChartKind.Area, ChartKind.StackedArea].indexOf(props.chartKind) >= 0
    })

    const showLineWidth = computed(() => {
      return [ChartKind.Line, ChartKind.Area, ChartKind.StackedArea].indexOf(props.chartKind) >= 0
    })

    const showSymbol = computed(() => {
      return [ChartKind.Line, ChartKind.Area, ChartKind.StackedArea].indexOf(props.chartKind) >= 0
    })
    const symbolItems = computed(() => {
      const symbols = ['none', 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow']
      return symbols.map((symbol) => {
        return { value: symbol, text: symbol }
      })
    })

    return {
      dialog,
      showOpacity,
      showLineWidth,
      showSymbol,
      symbolItems,
    }
  },
})
