
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useDashboardManager } from '@/metrics/use-dashboards'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashTableFormPanes',
  components: {
    Splitpanes,
    Pane,
    ForceReloadBtn,
  },

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '1700px',
    },
  },

  setup(props, ctx) {
    const form = shallowRef()
    const isValid = shallowRef(false)

    const dashMan = useDashboardManager()
    function submit() {
      if (!form.value.validate()) {
        return
      }

      dashMan.updateTable(props.dashboard).then((dash) => {
        ctx.emit('saved', dash)
      })
    }

    return {
      form,
      isValid,

      dashMan,
      submit,
    }
  },
})
