
import { defineComponent } from 'vue'

import { useTitle } from '@vueuse/core'
import { useUser } from '@/org/use-users'

export default defineComponent({
  name: 'UserProfile',

  setup() {
    const title = 'Profile'
    useTitle(title)

    const user = useUser()

    return {
      title,
      user,
    }
  },
})
