
import { defineComponent, PropType } from 'vue'

// Composables
import { UseUql } from '@/use/uql'

// Components
import WhereMenu from '@/tracing/query/WhereMenu.vue'
import AggByMenu from '@/tracing/query/AggByMenu.vue'
import GroupByMenu from '@/tracing/query/GroupByMenu.vue'

export default defineComponent({
  name: 'AdvancedMenu',
  components: { WhereMenu, AggByMenu, GroupByMenu },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    aggDisabled: {
      type: Boolean,
      default: false,
    },
  },
})
