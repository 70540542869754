
import { defineComponent } from 'vue'

// Composables
import { useUser } from '@/org/use-users'

export default defineComponent({
  name: 'UserMenu',

  setup() {
    const user = useUser()
    return { user }
  },
})
