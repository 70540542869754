
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { Project } from '@/org/use-projects'

export default defineComponent({
  name: 'ProjectSettings',

  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const title = computed(() => {
      return `Settings | ${props.project.name}`
    })
    useTitle(title)
  },
})
