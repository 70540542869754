
import { defineComponent, shallowRef, computed } from 'vue'

// Composables
import { useDsn } from '@/org/use-projects'

export default defineComponent({
  name: 'CollectorTabs',

  props: {
    dsn: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const activeTab = shallowRef()
    const dsn = useDsn(computed(() => props.dsn))

    const otlpGrpc = computed(() => {
      return `
processors:
  resourcedetection:
    detectors: [env, system]
  cumulativetodelta:
  batch:
    send_batch_size: 10000
    timeout: 10s

receivers:
  otlp:
    protocols:
      grpc:
      http:
  hostmetrics:
    scrapers:
      cpu:
      disk:
      filesystem:
      load:
      memory:
      network:
      paging:

exporters:
  debug:
  otlp/uptrace:
    endpoint: ${dsn.grpcEndpoint}
    tls: { insecure: ${dsn.insecure} }
    headers:
      uptrace-dsn: '${props.dsn}'

service:
  pipelines:
    traces:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlp/uptrace, debug]
    metrics:
      receivers: [otlp]
      processors: [cumulativetodelta, batch]
      exporters: [otlp/uptrace, debug]
    metrics/host:
      receivers: [hostmetrics]
      processors: [cumulativetodelta, batch, resourcedetection]
      exporters: [otlp/uptrace, debug]
    logs:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlp/uptrace, debug]
      `.trim()
    })

    const otlpHttp = computed(() => {
      return `
processors:
  resourcedetection:
    detectors: [env, system]
  cumulativetodelta:
  batch:
    send_batch_size: 10000
    timeout: 10s

receivers:
  otlp:
    protocols:
      grpc:
      http:
  hostmetrics:
    scrapers:
      cpu:
      disk:
      filesystem:
      load:
      memory:
      network:
      paging:

exporters:
  debug:
  otlphttp/uptrace:
    endpoint: ${dsn.httpEndpoint}
    headers:
      uptrace-dsn: '${props.dsn}'

service:
  pipelines:
    traces:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlphttp/uptrace, debug]
    metrics:
      receivers: [otlp]
      processors: [cumulativetodelta, batch]
      exporters: [otlphttp/uptrace, debug]
    metrics/host:
      receivers: [hostmetrics]
      processors: [cumulativetodelta, batch, resourcedetection]
      exporters: [otlphttp/uptrace, debug]
    logs:
      receivers: [otlp]
      processors: [batch]
      exporters: [otlphttp/uptrace, debug]
      `.trim()
    })

    return { activeTab, otlpGrpc, otlpHttp }
  },
})
