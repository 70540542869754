
import { defineComponent, set, del, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql, BackendQueryInfo } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'

// Components
import GridItemFormPanes from '@/metrics/GridItemFormPanes.vue'
import SinglePanel from '@/components/SinglePanel.vue'
import PanelSection from '@/components/PanelSection.vue'
import UnitPicker from '@/components/UnitPicker.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import GridItemTable from '@/metrics/GridItemTable.vue'

// Misc
import { requiredRule } from '@/util/validation'
import { Dashboard, TableGridItem, ColumnInfo } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemTableForm',
  components: {
    GridItemFormPanes,
    SinglePanel,
    PanelSection,
    UnitPicker,
    MetricsPicker,
    MetricsQueryBuilder,
    GridItemTable,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    tableGrouping: {
      type: Array as PropType<string[]>,
      required: true,
    },
    gridItem: {
      type: Object as PropType<TableGridItem>,
      required: true,
    },
  },

  setup(props, ctx) {
    const uql = useUql()

    const rules = { title: [requiredRule] }
    const activeMetrics = useActiveMetrics(computed(() => props.gridItem.params.metrics))

    watch(
      () => props.gridItem.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.gridItem.params.query = query
      },
    )

    function onTableQuery(query: BackendQueryInfo) {
      uql.setQueryInfo(query)
    }

    function onTableColumns(columns: ColumnInfo[]) {
      const unused = new Set(Object.keys(props.gridItem.params.columnMap))

      for (let col of columns) {
        if (col.isGroup) {
          continue
        }
        unused.delete(col.name)
        if (col.name in props.gridItem.params.columnMap) {
          continue
        }
        set(props.gridItem.params.columnMap, col.name, {
          unit: col.unit,
        })
      }

      for (let colName of unused.values()) {
        del(props.gridItem.params.columnMap, colName)
      }
    }

    return {
      uql,

      rules,

      activeMetrics,
      onTableColumns,
      onTableQuery,
    }
  },
})
