var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"gridStackEl",staticClass:"grid-stack"},_vm._l((_vm.rowItems),function(item){return _c('div',{key:item.id,staticClass:"grid-stack-item",attrs:{"id":`gs-item-${item.id}`,"gs-id":item.id,"gs-w":item.width,"gs-h":item.height,"gs-x":item.xAxis,"gs-y":item.yAxis,"gs-auto-position":_vm.gsAutoPosition}},[_c('div',{staticClass:"grid-stack-item-content"},[_vm._t("item",null,null,{
          attrs: { gridItem: item, height: _vm.itemHeight(item) },
          on: {
            ready() {
              _vm.resizeItem(item)
            },
          },
        })],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }