
import { defineComponent, ref, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import CustomDurationPicker from '@/components/date/CustomDurationPicker.vue'
import CustomDateRangePicker from '@/components/date/CustomDateRangePicker.vue'
import DateRange from '@/components/date/DateRange.vue'

export default defineComponent({
  name: 'DateRangePickerMenu',
  components: {
    CustomDurationPicker,
    CustomDateRangePicker,
    DateRange,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    forceDateRange: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const menu = ref(false)

    function applyDuration(ms: number) {
      props.dateRange.changeDuration(ms)
      props.dateRange.reloadNow()
      menu.value = false
    }

    function applyRange(gte: Date, lt: Date) {
      props.dateRange.change(gte, lt)
      menu.value = false
    }

    return {
      menu,

      applyDuration,
      applyRange,
    }
  },
})
